// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <strong><span class=\"oa-forest\">\r\n        "
    + container.escapeExpression(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " Goal Achieved!\r\n        </span></strong>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.onscheduleRemainingQualAmountNumber : depth0),0,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <strong><span class=\"oa-forest\">\r\n            "
    + container.escapeExpression(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " On Schedule!\r\n            </span></strong>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <strong>\r\n                <span class=\"oa-lake\" id=\"leadersConference-on-schedule-remaining-amount\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.onscheduleRemainingQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n            </strong> \r\n            "
    + alias3(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + " needed to reach goal of \r\n            <strong>\r\n                <span class=\"oa-pumpkin\" id=\"leadersConference-on-schedule-amount\">"
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.onscheduleQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "</span>\r\n            </strong>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "oa-banner-red";
},"10":function(container,depth0,helpers,partials,data) {
    return "oa-lake";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <strong><span class=\"oa-forest\">Lives Goal Achieved!</span></strong>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.onscheduleRemainingLives : depth0),0,{"name":"compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "            <strong><span class=\"oa-forest\">Lives On Schedule!</span></strong>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <strong>\r\n                <span class=\"oa-lake\" id=\"leadersConference-on-schedule-remaining-lives\" >"
    + alias4(((helper = (helper = helpers.onscheduleRemainingLives || (depth0 != null ? depth0.onscheduleRemainingLives : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"onscheduleRemainingLives","hash":{},"data":data}) : helper)))
    + "</span>\r\n            </strong> lives needed to reach goal of \r\n            <strong>\r\n                <span class=\"oa-pumpkin\" id=\"leadersConference-on-schedule-lives\" >"
    + alias4(((helper = (helper = helpers.onscheduleLives || (depth0 != null ? depth0.onscheduleLives : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"onscheduleLives","hash":{},"data":data}) : helper)))
    + "</span>\r\n            </strong>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "        <h4>\r\n        <a href=\"https://pages.oneamerica.com/ILC-Rules-IBP\" target=\"_blank\" rel=\"noopener noreferrer\">Rules<i class=\"fa fa fa-external-link oa-banner-blue\" ></i>\r\n        </a> \r\n        </h4>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "        <h4>\r\n        <a href=\"https://pages.oneamerica.com/LC-Rules-CA\" target=\"_blank\" rel=\"noopener noreferrer\">Rules<i class=\"fa fa fa-external-link oa-banner-blue\"></i></a> \r\n        <h4>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<li id=\"leadersConference-on-schedule-amount-goal-text\" >\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>    \r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingQualAmountNumber : depth0),0,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n<li>\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span class=\"actual-amount-number "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualAmountNumber : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\" >\r\n            "
    + alias3((helpers.currencyFormat || (depth0 && depth0.currencyFormat) || alias2).call(alias1,(depth0 != null ? depth0.actualQualAmount : depth0),true,{"name":"currencyFormat","hash":{},"data":data}))
    + "\r\n        </span>\r\n    </strong>\r\n    actual "
    + alias3(((helper = (helper = helpers.measureLabel || (depth0 != null ? depth0.measureLabel : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"measureLabel","hash":{},"data":data}) : helper)))
    + "\r\n</li>\r\n\r\n<li id=\"leadersConference-on-schedule-lives-goal-text\">\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.requiredRemainingLives : depth0),0,{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n<li>\r\n    <i class=\"fa-li fa fa-arrow-right\"></i>\r\n    <strong>\r\n        <span class=\"actual-lives "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.actualLives : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\" >\r\n            "
    + alias3(((helper = (helper = helpers.actualLives || (depth0 != null ? depth0.actualLives : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"actualLives","hash":{},"data":data}) : helper)))
    + "\r\n        </span>\r\n    </strong> actual lives\r\n</li>\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["pc-leaders-apr-status-bullet"],depth0,{"name":"pc-leaders-apr-status-bullet","hash":{"toggleType":"on-schedule"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n<li class=\"margin-top-10\">           \r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"ib",{"name":"compare","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\r\n";
},"usePartial":true,"useData":true});
