/* global Backbone: false, Marionette:false, _:false */

/**
 * Display result list for a Pending Policy Search.
 *
 * This view relies almost entirely on jQuery DataTables, which uses ajax calls for the initial
 * population of the data, as well as for sorting and paging.
 *
 * Created by jbell on 2/23/16.
 */

var ClientNameSearchResultsViewModel = require('../viewModels/client-name-search-results-vm');

var config               = require('../../../config/config');
var debugModule          = require('../../../modules/debug/debugModule').init();
var template             = require('../templates/client-name-search-results-t.hbs');
var utils                = require('../../../utils/utils');

// Need to include this, otherwise unit tests blow up.
require('../../../utils/hb-helpers');

// load the global partials.
require('../../../partials');

var userChannel = Backbone.Radio.channel('user');

var ClientNameSearchResultsView = Marionette.ItemView.extend({

    pageOptions: ['searchTerm'],

    template: template,

    ui: {
        clientResultsWrapper       : '#clientSearchResults',
        dataTable                  : '#policySearchResults',
        responsiveTableInstruction : '.table-responsive-instruction'
    },

    initialize: function (options) {

        this.spinnerChannel = Backbone.Radio.channel('spinner');

        if (!options.model) {
            this.model = new ClientNameSearchResultsViewModel();
        }

        this.mergeOptions(options, this.pageOptions);

        //check has capability using userChannel request
        var canSearchPolicyByProducer = userChannel.request('hasCapability', 
                'Policy_Search_by_Producer');
        
        this.model.set('hasPolicySearchByProducer', canSearchPolicyByProducer);

    },

    onRender: function () {

        var _this = this;

        this.spinnerChannel.trigger('show', {
            viewScope : this,
            position  : 'fixed'
        });

        if (!this.dataTableOptions) {
            this.dataTableOptions = this._setDataTableOptons();
        }

        var table = this.ui.dataTable.DataTable(this.dataTableOptions)
        .on('xhr', function($event, dataTableSettings, jsonResponse, xhr) {

            _this.spinnerChannel.trigger('hide', _this);
            
            // Make sure we're getting the info back that we should expect
            if (!jsonResponse || _.isUndefined(jsonResponse.recordsTotal) || !xhr) {

                _this.trigger('error');
                return;
            }
            
            // Handle any non-200 HTTP responses
            // after the readyState has been set to 4 (DONE)
            if (xhr.readyState === 4 && xhr.status !== 200) {
                _this.trigger('error');
                return;
            }            
        });

        utils.bindDataTableEvents({
            dtObject            : table, 
            viewScope           : this, 
            viewName            : 'client-name-search-results-v: ', 
            spinnerChannel      : this.spinnerChannel,
            debugModule         : debugModule,
            addOrderListener    : true
        });

        utils.formatDataTable(this.$el.find('.dataTables_wrapper'));
    },

    _setDataTableOptons : function _setDataTableOptons () {
        var startIndex = 0;
        var pageLength = 25;
        var orderCol   = 0;
        var orderDir   = 'asc';

        var dataTableOptions;

        // Use the available options for datatables setup
        if (this.options) {
            if (this.options.start) {
                startIndex = Number(this.options.start);
            }
            if (this.options.length) {
                pageLength = Number(this.options.length);
            }
            if (this.options.col) {
                orderCol = Number(this.options.col);
            }
            if (this.options.dir) {
                orderDir = this.options.dir;
            }
            if (_.has(this.options, 'noCache')) {
                this.cache.cacheStore = false;
            }
        }

        var columnDefs = [
            {data : 'customerLexicalName', name : 'customerName'},
            {data : 'dateOfBirth', name : 'dateOfBirth', render : utils.dataTableFormatDate},
            {data : 'relationship', name : 'relationship'},
            {
                data   : 'policyNumber', name : 'policyNumber',
                render : utils.renderDataTablePolicyNumber
            },
            {data : 'productName', name : 'productName'},
            {data : 'receivedDate', name : 'receivedDate', render : utils.dataTableFormatDate},
            {data : 'issueDate', name : 'issueDate', render : utils.dataTableFormatDate},
            {
                data        : 'policyStatus.description',
                name        : 'policyStatus',
                createdCell : _.bind(this._setStatusCellColor, this)
            }
        ];

        // In order to prevent "null" from being shown in IE/Edge, an
        // empty string will be set as the defaultContent for each column.
        utils.setDatatablesDefaultContent(columnDefs, '');

        var url = config.apiUrlRoot + 'policies?' +
            'customerName=' + encodeURIComponent(this.searchTerm);

        // Get common data table options
        dataTableOptions = utils.getDatatableOptions(this, url, true);

        _.extend(dataTableOptions, {

            // column definitions. Length MUST match number of columns in template
            columns : columnDefs,

            // 0-based start index
            displayStart : startIndex,

            // show info, length control, pagination, table, info, length, pagination
            dom : 'ilptilp',

            language : {
                info : 'Showing _START_ to _END_ of _TOTAL_ entries for <strong>"' +
                this.searchTerm + '"</strong>',

                lengthMenu : 'Display _MENU_ records'
            },

            // initial sorting
            order : [orderCol, orderDir],

            // initial page length
            pageLength : pageLength
            
        });

        return dataTableOptions;
    },

    _setStatusCellColor: function (td, cellData, rowData, row, col) {
        var cellClass       = '';
        var cellTemplate    = _.template(
            '<span class="label<%= cellClass %>"><%= cellData %></span>'
        );
        var acordHoldingStatus;
        var description;
        var status;

        if (rowData && rowData.policyStatus) {

            // Update policyStatus properties, if that contain dataAvailability object
            rowData              = utils.nullifyPolicyStatusNotAvailableProps(rowData);
            acordHoldingStatus   = rowData.policyStatus.acordHoldingStatus;
            description          = rowData.policyStatus.description;
            status               = rowData.policyStatus.status;
            cellData             = description;
        }

        cellClass = ' ' + utils.getCssClassForStatus(description, status, acordHoldingStatus);

        Backbone.$(td).html(cellTemplate({ cellClass : cellClass, cellData  : cellData }));
    }
});

module.exports = ClientNameSearchResultsView;
