/* global require */

/**
* Extending Performance center dial chart Base view for Chairmans Trip (CT) 
*/
var utils = require('../../../utils/utils');
var PerformanceCenterDialBaseView = require('./performance-center-dial-base-v');
var performanceCenterDialCTTemplate
    = require('../templates/performance-center-dial-ct-t.hbs');

var PerformanceCenterDialCTView = PerformanceCenterDialBaseView.extend({
    pdfLink: {
        ib: 'https://pages.oneamerica.com/CT-Rules-IBP',
        retail: 'https://pages.oneamerica.com/CT-Rules-CAP'
    },
    template: performanceCenterDialCTTemplate,
    buildDialChartOptions: function () {
        // Set the PDF link
        this._setRulesHref(this.model.get('type'));

        //*** Qualifying Amount Qualified ***/
        var actualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('actualQualAmount'), false, true
        );
        this.model.set('actualAmountNumber', actualAmountNumber);

        var onscheduleRemainingQualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('onscheduleRemainingQualAmount'), true, true
        );
        this.model.set('onscheduleRemainingQualAmountNumber', onscheduleRemainingQualAmountNumber);

        var requiredAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('requiredQualAmount'), true, true
        );

        var requiredRemainingQualAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('requiredRemainingQualAmount'), true, true
        );
        this.model.set('requiredRemainingQualAmountNumber', requiredRemainingQualAmountNumber);

        //check mark
        this.checkMark = this.model.get('qualifyingGoalAchievedFlag');

        this.dialChartOptionsOne = {
            chartType: 'double',
            outerValue: actualAmountNumber,
            innerValue: this.model.get('actualLives'),
            classLabelOne: 'chart-double-text-one',
            classLabelTwo: 'chart-double-text-two',
            textLabelOne: utils.formatAsCurrency(actualAmountNumber),
            textLabelTwo: this.model.get('actualLives') + ' lives',
            checkMark: this.checkMark,
            outerChartOptions: {
                maxValue: requiredAmountNumber,
                scaleColor: '#ffffff'
            },
            innerChartOptions: {
                maxValue: this.model.get('requiredLives'),
                scaleColor: '#ffffff'
            }
        };

        // When checkMark is false, check to see if inner/outer ring should be green
        if (!this.checkMark) {
            if (requiredRemainingQualAmountNumber === 0) {
                this.dialChartOptionsOne.outerChartOptions.barColor = this.dialColorComplete;
            }

            if (this.model.get('requiredRemainingLives') === 0) {
                this.dialChartOptionsOne.innerChartOptions.barColor = this.dialColorComplete;
            }
        }

        //*** Qualifying Amount onSchedule ***/
        var onScheduleAmountNumber = utils.isoCurrencyToNumber(
            this.model.get('onscheduleQualAmount'), true, true
        );

        this.dialChartOptionsTwo = {
            chartType: 'double',
            outerValue: actualAmountNumber,
            innerValue: this.model.get('actualLives'),
            classLabelOne: 'chart-double-text-one',
            classLabelTwo: 'chart-double-text-two',
            textLabelOne: utils.formatAsCurrency(actualAmountNumber),
            textLabelTwo: this.model.get('actualLives') + ' lives',
            checkMark: this.checkMark,
            outerChartOptions: {
                maxValue: onScheduleAmountNumber,
                scaleColor: '#ffffff'
            },
            innerChartOptions: {
                maxValue: this.model.get('onscheduleLives'),
                scaleColor: '#ffffff'
            }
        };

        // When checkMark is false, check to see if inner/outer ring should be green
        if (!this.checkMark) {

            if (this.model.get('onscheduleRemainingQualAmountNumber') === 0) {
                this.dialChartOptionsTwo.outerChartOptions.barColor = this.dialColorComplete;
            }

            if (this.model.get('onscheduleRemainingLives') === 0) {
                this.dialChartOptionsTwo.innerChartOptions.barColor = this.dialColorComplete;
            }
        }
    },

    /**
     * Set the link to the "Rules" PDF based on `type`.
     * @private
     */
    _setRulesHref: function _setRulesHref(type) {
        var link = this.pdfLink[type];
        this.model.set('pdfLink', link);
    }

});

module.exports = PerformanceCenterDialCTView;
