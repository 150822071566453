/* global Backbone:false, _:false */
/**
 * View used to display the Policy Statement Link on
 * region defined in policy detail Identification section
 * 
 */

 import config from '../../../config/config';

 import PolicyStatementLinkModel from '../models/policy-statement-link-m';
 import template from '../templates/policy-statement-link-t.hbs';
 import utils from '../../../utils/utils';
 
 
 const spinnerChannel = Backbone.Radio.channel('spinner');
 
 const PolicyStatementLinkView = Backbone.Marionette.ItemView.extend({
 
     template : template,
 
     ui : {
         policyStatementLinkPlacer : '#policy-statement-link-placer'
     },
     
     errors : {
         missingPolicyID : 'Policy ID is missing to fetch policy statement documents',
         noDocuments     : 'No statement available'
     },
 
     initialize() {
 
         if (!this.options.policyId) {
             throw new Error(this.errors.missingPolicyID);
         }
 
         this.model = new PolicyStatementLinkModel({
             policyId :this.options.policyId,
             isActiveStatusView:this.options.isActiveStatusView,
             applicationDocHref:this.options.applicationDocHref
         });
     },
 
     onBeforeShow() {
         this.listenTo(this.model, 'sync', this._showStatementLink);
         this.listenTo(this.model, 'error', this._handleServerError);
         this.model.fetch();
 
         spinnerChannel.trigger('show', {
             viewScope   : this,
             size        : 'small',
             position    : 'static'
         });
     },
 
     /**
      * Show link received from statement service
      * 
      * @private
      */
     _showStatementLink() {
 
         spinnerChannel.trigger('hide', this);
 
         // Need to flag to display messages/link after getting data
         // till that time FE need to display spinner animation
        //  this.model.set('policyStatementDataReady', true);
 
         const links = this.model.get('links');
         let link;
         let policyStatementLink = '';
 
         if(!_.isEmpty(links)) {
             link = links[0].href;
             policyStatementLink = utils.buildHrefUrlForDocument(config.apiDocUrlRoot + link);
             this.model.set('policyStatementLink', policyStatementLink);
         }
        // Spinner is displayed for both policy statement link and 
        // eApp loader until data is retrieed.

         this.model.set('isNoneAvailable', _.isEmpty(policyStatementLink) 
         && _.isEmpty(this.options.applicationDocHref));
         this.model.set('policyStatementDataReady', true);
         this.render();
     },
 
     /**
      * Handle error occured while fetching iGo service
      * 
      * @param {object} model model object
      * @param {object} response Service response
      * 
      * @private
      */
     _handleServerError(model, response) {
 
         spinnerChannel.trigger('hide', this);
         this.model.set('policyStatementDataReady', true); 
         if (response && response.status) {
 
             if (response.status === 404 || response.status === 403 || response.status === 500) {
                 this.model.set('isNoneAvailable', _.isEmpty(this.options.applicationDocHref));
                 this.model.set('errorMessage', this.errors.noDocuments);
             }
 
             this.render();
         }
     }
     
 });
 
 module.exports = PolicyStatementLinkView;