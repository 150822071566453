// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.show : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <span class=\"gray-50 small\">\r\n                        <i class=\"ace-icon fa fa-angle-double-right gray-30\"></i>\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n                        <span class=\"text-nowrap\">\r\n                            <button aria-label=\"information\" class=\"fa-stack information-modal pointer\" id=\"policy-status-help-text-icon\">\r\n                                <i class=\"fa fa-square fa-stack-2x\"></i>\r\n                                <i class=\"fa fa-info fa-inverse fa-stack-1x\"></i>\r\n                            </button>\r\n                        </span>\r\n                    </span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.acordHoldingStatus : stack1), depth0))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.status : stack1), depth0))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.statusDetail : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                &mdash; "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.statusDetail : stack1), depth0))
    + "\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isAbandoned),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.status : stack1),"Terminated",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.statusDetail : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                        &mdash; "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.statusDetail : stack1), depth0))
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                        &mdash; "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.description : stack1), depth0))
    + "\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <!-- PAGE CONTENT BEGINS -->\r\n        <div class=\"row margin-btm-10\">\r\n            <div id=\"last-refresh-date\" class=\"col-lg-5\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastRefreshDate : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,((stack1 = (data && data.root)) && stack1.showRequirements),(depth0 != null ? depth0.exchangeHistory : depth0),{"name":"compare","hash":{"operator":"||"},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\r\n"
    + ((stack1 = container.invokePartial(partials["policy-identification"],depth0,{"name":"policy-identification","hash":{"applicationDocHref":((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.applicationDocHref : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.relatedPolicies : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.application : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyValue : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.policyStatus : depth0)) != null ? stack1.onlySummaryDataAvailable : stack1),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.showInvestmentsForActive),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFinancialTab : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.coverage : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.payout : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.payout : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.payments : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.requirements : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || alias2).call(alias1,(depth0 != null ? depth0.exchangeHistory : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.showInvestmentsForPending),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        \r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyNotes : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"oa-lake\">Last Refresh Date: </span>"
    + container.escapeExpression((helpers.dateTimeFormat || (depth0 && depth0.dateTimeFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lastRefreshDate : depth0),"MM/DD/YYYY, hh:mm A z",{"name":"dateTimeFormat","hash":{},"data":data}))
    + "\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["jump-links"],depth0,{"name":"jump-links","hash":{"exchangeHistory":(depth0 != null ? depth0.exchangeHistory : depth0),"requirements":(depth0 != null ? depth0.requirements : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"well\" id=\"related-policies-links\">\r\n"
    + ((stack1 = container.invokePartial(partials["related-policies"],depth0,{"name":"related-policies","hash":{"showModalPremium":false},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["application-status"],(depth0 != null ? depth0.application : depth0),{"name":"application-status","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.displayPolicyHighlights),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-highlights"],depth0,{"name":"policy-highlights","hash":{"ltcCoverageDetail":((stack1 = ((stack1 = (depth0 != null ? depth0.coverage : depth0)) != null ? stack1.coverageDetail : stack1)) != null ? stack1.ltcCoverageDetail : stack1),"policyValue":(depth0 != null ? depth0.policyValue : depth0),"config":(depth0 != null ? depth0.policyHighlightsConfig : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.customerRoles : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-relationships"],(depth0 != null ? depth0.policyRelationshipCategories : depth0),{"name":"policy-relationships","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.investment : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <h2 class=\"header\">Investments</h2>\r\n                <div id=\"investments-tabs-region\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.isActive),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <ul class=\"nav nav-tabs\" role=\"tablist\">\r\n                        <li class=\"active\">\r\n                            <a href=\"#accountBalance\" data-target=\"#accountBalance\" aria-controls=\"Account-Balance\" role=\"tab\"\r\n                                data-toggle=\"tab\">Account Balance</a>\r\n                        </li>\r\n                        <li>\r\n                            <a href=\"#currentElections\" id=\"currentElectionsTab\" data-target=\"#currentElections\" aria-controls=\"Current-Elections\" role=\"tab\"\r\n                                data-toggle=\"tab\">Current Elections</a>\r\n                        </li>\r\n                    </ul>\r\n                    <div class=\"tab-content table-responsive\">\r\n                        <div role=\"tabpanel\" class=\"tab-pane tab-pane-border tab-pane-spacing active\" id=\"accountBalance\">\r\n"
    + ((stack1 = container.invokePartial(partials["investment-allocation-active"],depth0,{"name":"investment-allocation-active","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\r\n                        <div role=\"tabpanel\" class=\"tab-pane tab-pane-border tab-pane-spacing\" id=\"currentElections\">\r\n"
    + ((stack1 = container.invokePartial(partials["current-elections-active"],depth0,{"name":"current-elections-active","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "                     <strong>Section Unavailable</strong>\r\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isActiveOrProposedAcordHoldingStatus : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(53, data, 0),"data":data})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <h2 class=\"header\">Policy Financials</h2>\r\n                <div id=\"policy-financial-tabs-region\">\r\n                    <ul class=\"nav nav-tabs\" role=\"tablist\">\r\n                        <li class=\"active\">\r\n                            <a href=\"#policyBillingPayment\" data-target=\"#policyBillingPayment\" aria-controls=\"Billing/Payment\" role=\"tab\" data-toggle=\"tab\">Billing / Payment</a>\r\n                        </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isActiveAccordHoldingStatus : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isActiveAccordHoldingStatus : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPremiumSchedule : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </ul>\r\n                    <div class=\"tab-content table-responsive\">\r\n                        <div role=\"tabpanel\" class=\"tab-pane tab-pane-border tab-pane-spacing active\" id=\"policyBillingPayment\">\r\n"
    + ((stack1 = container.invokePartial(partials["billing-payment-info"],depth0,{"name":"billing-payment-info","hash":{"payout":(depth0 != null ? depth0.payout : depth0),"billingDetail":(depth0 != null ? depth0.billingDetail : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isActiveAccordHoldingStatus : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loan : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    return "                        <li>\r\n                            <a href=\"#policyLoanInformation\" data-target=\"#policyLoanInformation\" aria-controls=\"Loan Information\" role=\"tab\" data-toggle=\"tab\">Loan Information</a>\r\n                        </li>\r\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "                        <li id=\"transactionHistoryTab\">\r\n                            <a href=\"#transactionHistoryRegion\" data-target=\"#transactionHistoryRegion\" aria-controls=\"Transaction-History\" role=\"tab\" data-toggle=\"tab\">Transaction History</a>\r\n                        </li>\r\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isStatusDetail : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.if_pmq_tab_visible || (depth0 && depth0.if_pmq_tab_visible) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showCurrentMode : depth0),(depth0 != null ? depth0.alternatePremModeQuotesList : depth0),(depth0 != null ? depth0.showPremiumModeQuotes : depth0),{"name":"if_pmq_tab_visible","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    return "                                    <li>\r\n                                        <a href=\"#premiumModeQuotesRegion\" data-target=\"#premiumModeQuotesRegion\" aria-controls=\"Premium Mode Quotes\" role=\"tab\" \r\n                                        data-toggle=\"tab\">Premium Mode Quotes</a>\r\n                                    </li>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotEmpty || (depth0 && depth0.isNotEmpty) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loan : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        <div role=\"tabpanel\" class=\"tab-pane tab-pane-with-info\" id=\"transactionHistoryRegion\">     \r\n                        </div>\r\n                        <div role=\"tabpanel\" class=\"tab-pane tab-pane-with-info\" id=\"premiumModeQuotesRegion\">     \r\n"
    + ((stack1 = container.invokePartial(partials["premium-mode-quotes"],depth0,{"name":"premium-mode-quotes","hash":{"showCurrentMode":(depth0 != null ? depth0.showCurrentMode : depth0),"alternatePremiumModesList":(depth0 != null ? depth0.alternatePremModeQuotesList : depth0),"billingDetail":(depth0 != null ? depth0.billingDetail : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div role=\"tabpanel\" class=\"tab-pane\" id=\"policyLoanInformation\">\r\n"
    + ((stack1 = container.invokePartial(partials["loan-information"],(depth0 != null ? depth0.loan : depth0),{"name":"loan-information","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"53":function(container,depth0,helpers,partials,data) {
    return "                    <h2 class=\"header\">Policy Financials</h2>\r\n                <div id=\"policy-financial-section-unavailable\" class=\"bigger-115\">\r\n                    <strong>Section unavailable</strong>\r\n                </div>\r\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.coverages,(depth0 != null ? depth0.coverage : depth0),{"name":"coverages","hash":{"ltcCoverage":(depth0 != null ? depth0.ltcCoverage : depth0),"relatedPolicies":(depth0 != null ? depth0.relatedPolicies : depth0),"billing":(depth0 != null ? depth0.billingDetail : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["income-payment"],(depth0 != null ? depth0.payout : depth0),{"name":"income-payment","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["income-options"],(depth0 != null ? depth0.payout : depth0),{"name":"income-options","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["additional-premium"],(depth0 != null ? depth0.payments : depth0),{"name":"additional-premium","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.showRequirements),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-requirements"],(depth0 != null ? depth0.requirements : depth0),{"name":"policy-requirements","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["policy-1035-exchange-history-comments"],depth0,{"name":"policy-1035-exchange-history-comments","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.investment : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["investment-allocation-pending"],depth0,{"name":"investment-allocation-pending","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.notes,depth0,{"name":"notes","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n        <h1>Policy Detail\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.policyStatus : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </h1>\r\n\r\n"
    + ((stack1 = container.invokePartial(partials["policy-workflow-status-help-text-modal"],depth0,{"name":"policy-workflow-status-help-text-modal","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n    </div>\r\n    <!-- /.page-header -->\r\n\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasPolicyDetails : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n</div>\r\n<!-- /.page-content -->";
},"usePartial":true,"useData":true});
