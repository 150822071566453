// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\r\n                    <span class=\"text-nowrap\">"
    + alias4(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + "\r\n                </h4>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "Chairman's Trip "
    + container.escapeExpression(((helper = (helper = helpers.conferenceYear || (depth0 != null ? depth0.conferenceYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"conferenceYear","hash":{},"data":data}) : helper)))
    + "</h4>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<!-- Modal -->\r\n<div class=\"modal fade\" id=\""
    + alias4(((helper = (helper = helpers.sectionType || (depth0 != null ? depth0.sectionType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionType","hash":{},"data":data}) : helper)))
    + "-help-text-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\""
    + alias4(((helper = (helper = helpers.sectionType || (depth0 != null ? depth0.sectionType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionType","hash":{},"data":data}) : helper)))
    + "HelpTextModalLabel\">\r\n    <div class=\"modal-dialog\" role=\"document\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\r\n                <h4 class=\"modal-title\" id=\""
    + alias4(((helper = (helper = helpers.sectionType || (depth0 != null ? depth0.sectionType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionType","hash":{},"data":data}) : helper)))
    + "HelpTextModalLabel\">"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.sectionType : depth0),"leadersConference",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.sectionType : depth0),"chairmansTrip",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n            <!-- /.modal-header -->\r\n            <div class=\"modal-body\">\r\n                <p>\r\n                    In addition to the production qualification shown, a producer must have 91.00%\r\n                    persistency or higher at the conclusion of the qualification period.  OneAmerica\r\n                    reserves the right to refuse to extend an invitation or rescind an invitation for\r\n                    any reason they deem appropriate.\r\n                    To review full qualification rules, click on the RULES link below.\r\n                </p>\r\n            </div>\r\n            <!-- /.modal-body -->\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-primary btn-close-info-text-modal\" data-dismiss=\"modal\">OK</button>\r\n            </div>\r\n            <!-- /.modal-footer -->\r\n        </div>\r\n        <!-- /.modal-content -->\r\n    </div>\r\n    <!-- /.modal-dialog -->\r\n</div>\r\n<!-- /.modal -->\r\n";
},"useData":true});
